@font-face {
  font-family: "Acumin-RPro";
  src:
    url("./assets/fonts/acumin-rpro.woff2") format("woff2"),
    url("./assets/fonts/acumin-rpro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-ItPro";
  src:
    url("./assets/fonts/acumin-itpro.woff2") format("woff2"),
    url("./assets/fonts/acumin-itpro.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Acumin-BdPro";
  src:
    url("./assets/fonts/acumin-bdpro.woff2") format("woff2"),
    url("./assets/fonts/acumin-bdpro.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-BdItPro";
  src:
    url("./assets/fonts/acumin-bditpro.woff2") format("woff2"),
    url("./assets/fonts/acumin-bditpro.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src:
    url("./assets/fonts/IBMPlexMono-Regular.woff2") format("woff2"),
    url("./assets/fonts/IBMPlexMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  background-image: url('./assets/GenZLandingPageWallpaper.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: "Acumin-RPro", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.logo {
  max-width: 150px;
  height: auto;
  margin-top: -40px;
  margin-bottom: 20px;
  align-self: center;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("./assets/GenZLandingPageWallpaper.svg");
  background-size: cover;
  background-position: center;
  background-color: transparent;
  font-family: "Acumin-RPro", sans-serif;
  padding: 20px;
}

/* Thank You Page Styling */
.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.thank-you-content {
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 600px;
  width: 100%;
}

/* Default Control Styling */
.react-select__control {
  @apply w-full h-14 border border-gray-300 rounded-lg bg-white text-lg cursor-pointer;
}

/* Disabled Control */
.react-select__control--is-disabled {
  @apply bg-gray-100 text-gray-500 cursor-not-allowed;
}

/* Placeholder Styling */
.react-select__placeholder {
  @apply text-gray-400;
}

/* Option Styling */
.react-select__option {
  @apply px-4 py-2 cursor-pointer;
}

/* Focused Option Styling */
.react-select__option--is-focused {
  @apply bg-gray-100; /* Light gray background when focused */
}

/* Selected Option Styling */
.react-select__option--is-selected {
  @apply bg-[#ff4f55] text-white; /* Blue background for selected options */
}

